@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  /* @apply bg-primary text-white; */
    /* Applying the primary background and text color */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* Handle vertical scrolling */
  background-color: #000000;
  /* Match the dark background color */
  /* overflow-y:auto; */
  /* Prevent horizontal scrolling */
}
.container {
  margin: 0 auto;
  /* Center container without extra margins */
  padding: 0;
  /* Avoid padding that could push content down */
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
